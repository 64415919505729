<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <div>
        <h1>Sheep</h1>
        <p>
          Ganz am Anfang bekamen wir von einem Nachbarn ein paar Merinoschafe. Seitdem haben sie sich vermehrt und
          und wir haben jetzt eine kleine Herde. Vor kurzem haben wir die Schafböcke entfernt, um zu verhindern, dass
          die Herde noch weiter wächst.
        </p>
        <picture>
          <source media="(max-width: 500px)" srcset="../../assets/images/sm-sheep.jpg">
          <img src="../../assets/images/sheep.jpg">
        </picture>

        <p>
          Schafe passen nicht gut in unsere derzeitige Projektphase, deshalb halten wir die Schafe in einem separaten
          Bereich, den sie mit den Pferden teilen. Einmal im Jahr, bevor die Sommerhitze einsetzt, scheren wir sie und
          verwenden die Wolle als Mulch für Bäume.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';

export default {
  name: "SheepDE",
  components: {
    Sidebar,
  },

};
</script>
